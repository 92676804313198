import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'deliveryadresse-offer',
  templateUrl: './deliveryadresse.component.html',
  styleUrls: ['./deliveryadresse.component.css']
})

export class DeliveryadresseComponent {

}
