import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'clientbank-offer',
  templateUrl: './clientbank.component.html',
  styleUrls: ['./clientbank.component.css']
})

export class ClientbankComponent {

}
