import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {InquiriesService} from './inquiries.service';
import {ActivatedRoute} from '@angular/router';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {Inquiry} from './inquiry.model';
import {ProjectsService} from '../projects/projects.service';
import {ProductSearchComponent} from '../product-search/product-search.component';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {Location} from '@angular/common';


@Component({
  selector: 'app-products',
  templateUrl: './inquiries2.component.html',
  styleUrls: ['./inquiries2.component.css']
})
export class InquiriesComponent implements OnInit {

  positionsColumns = [{prop: 'Position'}, {prop: 'ArtikelNr'}, {prop: 'Menge'}, {prop: 'VPreis'}, {prop: 'EPreis'}, {prop: 'Summe'},
    {prop: 'Bezeichnung1'}, {prop: 'Bemerkung'}];
  suppliersColumns = [{prop: 'Lieferant'}, {prop: 'Lieferprogramm'}, {prop: 'LieferantenNr'}, {prop: 'PLZ'}, {prop: 'Stadt'}];
  public id = null;
  protected message = '';
  inquiry = new Inquiry();

  suppliers = [];
  tempSuppliers = [];
  temp = [];
  loadingIndicator = false;
  selectedSuppliers = [];
  SelectionType = SelectionType;
  loadingIndicatorSuppliers = false;
  reorderable = true;

  selectedProducts = [];
  tempProducts = [];
  searchInquiryString: string;
  inquiriesNumbers: Array<any> = [];

  // Modal with component
  modalRef!: BsModalRef;
  bsModalRef!: BsModalRef;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild(DatatableComponent) tableProducts: DatatableComponent;

  constructor(private inquiriesService: InquiriesService, private projectsService: ProjectsService,
              private route: ActivatedRoute, private modalService: BsModalService, private location: Location) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.getInquiry(this.id);
      this.loadInquiriesNumbers();
      this.listSuppliers(); // TODO optimize
    });
  }

  getInquiry(id): void {
    this.loadingIndicator = true;
    this.inquiry = new Inquiry();
    this.inquiriesService.getInquiry(id).subscribe(
      (result) => {
        this.loadingIndicator = false;
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.inquiry = result.data;
          this.inquiry.positions = result.positions;
          this.tempProducts = result.positions;
          this.message = result.message;
          console.log(this.inquiry);
        } else {
          this.inquiry = null;
          console.log('No inquiry');
        }
      }
    );
  }

  listSuppliers(): void {
    this.loadingIndicatorSuppliers = true;
    this.inquiriesService.listSuppliers().subscribe(
      (result) => {
        this.loadingIndicatorSuppliers = false;
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.suppliers = result.data;
          this.tempSuppliers = result.data;
          this.message = result.message;
          console.log(this.suppliers);
        } else {
          this.inquiry = null;
          console.log('No suppliers');
        }
      }
    );
  }

  getFilteredData(): Array<string> {
    const IdsArray: Array<string> = [];
    this.inquiriesNumbers.forEach((value) => {
      IdsArray.push(value.AnfrageNr);
    });
    return IdsArray;
  }

  loadInquiriesNumbers(): void {
    this.inquiriesService.listInquiriesNumbers().subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.inquiriesNumbers = result.data;
          this.message = result.message;
          console.log(this.inquiriesNumbers);
        } else {
          this.inquiry = null;
          console.log('No projects');
        }
      }
    );
  }

  openModalWithComponent(): void {
    const modalOptions = {
      initialState: {},
      class: 'modal-lg'
    };
    this.bsModalRef = this.modalService.show(ProductSearchComponent, modalOptions);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  updateFilter(event): void {
    const val = event.target.value.toLowerCase();

    // filter our data
    const tempSuppliers = this.tempSuppliers.filter((d) => {
      if (d.Lieferant) {
        return d.Lieferant.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });

    // update the rows
    this.suppliers = tempSuppliers;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  updateFilterProducts(event): void {
    const val = event.target.value.toLowerCase();
    console.log(this.tempProducts);
    // filter our data
    const tempProducts = this.tempProducts.filter((d) => {
      if (d.Bezeichnung1) {
        return d.Bezeichnung1.toLowerCase().indexOf(val) !== -1 || !val;
      } else {
        return false;
      }
    });

    // update the rows
    this.inquiry.positions = tempProducts;
    // Whenever the filter changes, always go back to the first page
    this.tableProducts.offset = 0;
  }

  onSelect({selected}): void {
    console.log('Select Event', selected, this.selectedProducts);

    this.selectedProducts.splice(0, this.selectedProducts.length);
    this.selectedProducts.push(...selected);
  }

  onActivate(event): void {
    console.log('Activate Event', event);
  }

  onSelectSupplier({selected}): void {
    console.log('Select Event', selected, this.selectedSuppliers);

    this.selectedSuppliers.splice(0, this.selectedSuppliers.length);
    this.selectedSuppliers.push(...selected);
  }

  onActivateSupplier(event): void {
    console.log('Activate Event', event);
  }
  backClicked(): void {
    this.location.back();
  }
}
