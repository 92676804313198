<div class="row p-5">
  <div class="col ml-2">
    <h2 class="mb-4">Links:</h2>
    <div class="dropdown ml-5">
    <a class="dropdown-item" routerLink="/project" target="_blank"><h6>Project</h6></a>
    <a class="dropdown-item" routerLink="/inquiry" target="_blank"><h6>Inquiry</h6></a>
    <a class="dropdown-item" routerLink="/offer" target="_blank"><h6>offer</h6></a>
    <a class="dropdown-item" routerLink="/auftrag" target="_blank"><h6>auftrag</h6></a>
    <a class="dropdown-item" routerLink="/rechnung" target="_blank"><h6>rechnung</h6></a>
    <a class="dropdown-item" routerLink="/bestellung" target="_blank"><h6>bestellung</h6></a>
    <a class="dropdown-item" routerLink="/shipping" target="_blank"><h6>shipping</h6></a>
    <a class="dropdown-item" routerLink="/deliveryadresse" target="_blank"><h6>deliveryadresse</h6></a>
    <a class="dropdown-item" routerLink="/clientbank" target="_blank"><h6>clientbank</h6></a>
    <a class="dropdown-item" routerLink="/provision" target="_blank"><h6>provision</h6></a>
      <a class="dropdown-item" routerLink="/lieferchein" target="_blank"><h6>Lieferschein</h6></a>
  </div>
</div>
