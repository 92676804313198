import {Component, OnInit} from '@angular/core';
import {InquiriesService} from '../inquiries/inquiries.service';
import {ProjectsService} from './projects.service';
import {ProductsService} from '../products/products.service';
import {Project} from './project.model';
import {SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Article} from '../products/article.model';
import {ProjectArticle} from './project-article.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  public id = null;
  searchProjectString: string;
  SearchProductString: string;
  projectsNumbers: Array<any> = [];
  productNumbers: Array<any> = [];
  loadingIndicator = false;

  project = new Project();
  article = new Article();
  message = '';
  addArticleDict = {};
  buyPrice: number;
  quantityProject: number;
  projectMarge: number;
  vpreisProject: number;
  epreisProject: number;
  somme: number;


  reorderable = true;
  selectedProducts = [];
  SelectionType = SelectionType;

  inquiriesColumns = [{prop: 'AnfrageNR'}, {prop: 'Lieferant'}, {prop: 'Datum'}];

  constructor(private inquiriesService: InquiriesService, private projectsService: ProjectsService, private productService: ProductsService,
              private http: HttpClient, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.getProject(this.id);
      this.searchProjectString = this.id;
    });
    this.loadProjectsNumbers();
    this.loadProductNumbers();
  }

  getFilteredData(): Array<string> {
    const IdsArray: Array<string> = [];
    this.projectsNumbers.forEach((value) => {
      IdsArray.push(value.ProjektNR);
    });
    return IdsArray;
  }

  getProject(id): void {
    this.loadingIndicator = true;
    this.project = new Project();
    this.projectsService.getProject(id).subscribe(
      (result) => {
        this.loadingIndicator = false;
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.project = result.data;
          this.project.positions = result.positions;
          this.project.inquiries = result.inquiries;
          // change route
          this.router.navigate(['/project/' + id]);
          // this.tempProducts = result.positions;
          this.message = result.message;
          console.log(this.project);
        } else {
          this.project = null;
          console.log('No project');
        }
      }
    );
  }

  loadProjectsNumbers(): void {
    this.projectsService.listProjectsNumbers().subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.projectsNumbers = result.data;
          this.message = result.message;
          console.log(this.projectsNumbers);
        } else {
          this.project = null;
          console.log('No projects');
        }
      }
    );
  }

  /**
   * add the selected product to the array this.selectedProducts
   * @param selected: selected table item
   */
  onSelect({selected}): void {
    console.log('Select Event', selected, this.selectedProducts);
    this.selectedProducts.splice(0, this.selectedProducts.length);
    this.selectedProducts.push(...selected);
  }

  onActivate(event): void {
    console.log('Activate Event', event);
  }

  onSubmit(form: NgForm): void {
    console.log(form);
  }

  onKeyUp(event): void {
    console.log(event);
  }

  getFilteredProductData(): Array<string> {
    const IdsArray: Array<string> = [];
    this.productNumbers.forEach((value) => {
      IdsArray.push(value.ArtikelNr);
    });
    return IdsArray;
  }


  getProductAndAddToProject(id): void {
    this.productService.getProduct(id).subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.article = result.data;
          console.log('article', this.article);
          const newProjectArticle = new ProjectArticle();
          newProjectArticle.setArticleValues(this.article);
          newProjectArticle.setProjektNR(this.project.ProjektNR);
          this.addProjectArticle(newProjectArticle);
          // this.tempProducts = result.positions;
          console.log(newProjectArticle);
        } else {
          this.article = null;
          console.log('No product');
        }
      }
    );
  }

  loadProductNumbers(): void {
    this.productService.listProductNumbers().subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.productNumbers = result.data;
          this.message = result.message;
          console.log(this.productNumbers);
        } else {
          this.article = null;
          console.log('No Product');
        }
      }
    );
  }

  addProjectArticle(newProjectArticle: ProjectArticle): void {
    this.projectsService.addProjectArticle(newProjectArticle).subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          console.log(result);
          this.getProject(this.project.ProjektNR);
        }
      }
    );
  }

  addArticle(article: Article): void {
    console.log(article);
    this.productService.addArticle(article).subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          console.log(result);
        }
      }
    );
  }

  addProject(project: Project): void {
    console.log(project);
    this.projectsService.addProject(project).subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          console.log(result);
        }
      }
    );
  }

  removeProjectArticle(projectArticle: ProjectArticle): void {
    console.log(projectArticle);
    this.projectsService.removeProjectArticle(projectArticle).subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          console.log(result);
          this.getProject(this.project.ProjektNR);
        }
      }
    );
  }

  addCountry(addCountry: { country: string }): void {
    console.log(addCountry);
  }
}
