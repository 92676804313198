export class Article {
  ArtikelNr: string;
  Bezeichnung1: string;
  Bezeichnung2: string;
  Bezeichnung3: string;
  Bezeichnung4: string;
  Bezeichnung5: string;
  Bezeichnung6: string;
  Lieferant: string;
  Kunde: string;
  Industriebereich: string;
  Einheit: string;
  VPreis: number;
  Bemerkung: string;
  Erstelldatum: string;
  Änderungsdatum: string;
  EPreis: number;
  Datenblatt: string;
  Kontrolle: string;

  constructor(article?: Article) {
    if (article) {
      console.log('constructor', article);
      this.ArtikelNr = article.ArtikelNr;
      this.Bezeichnung1 = article.Bezeichnung1;
      this.Bezeichnung2 = article.Bezeichnung2;
      this.Bezeichnung3 = article.Bezeichnung3;
      this.Bezeichnung4 = article.Bezeichnung4;
      this.Bezeichnung5 = article.Bezeichnung5;
      this.Bezeichnung6 = article.Bezeichnung6;
      this.Lieferant = article.Lieferant;
      this.Kunde = article.Kunde;
      this.Industriebereich = article.Industriebereich;
      this.Einheit = article.Einheit;
      this.VPreis = article.VPreis;
      this.Bemerkung = article.Bemerkung;
      this.Erstelldatum = article.Erstelldatum;
      this.Änderungsdatum = article.Änderungsdatum;
      this.EPreis = article.EPreis;
      this.Datenblatt = article.Datenblatt;
      this.Kontrolle = article.Kontrolle;
      console.log('constructor 2', this);
    }
  }


}
