export class Inquiry {
  id: string;
  anfrageNr: string;
  lieferantenNr: string;
  lieferant: string;
  projektNr: string;
  Strasse: string;
  PLZ: string;
  Land: string;
  BelegDatum: string;
  positions: any[];
}
