import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ProjectArticle} from '../projects/project-article.model';
import {Article} from './article.model';

@Injectable({
  providedIn: 'root'
})

export class ProductsService {
  private wsSearchCompaniesURL = environment.backend + 'product.search';

  constructor(private http: HttpClient) {
  }

  searchProductsByString(searchString): Observable<any> {
    const url = this.wsSearchCompaniesURL;
    const data = {
      search_string: searchString
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(_ => console.log(`fetched product`)),
      catchError(this.handleError<any>(`get product`))
    );
  }

  getProduct(id): Observable<any> {
    const url = environment.backend + 'product.get';
    const data = {id};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(_ => console.log(`product`)),
      catchError(this.handleError<any>(`get product`))
    );
  }

  addArticle(newArticle: Article): Observable<any> {
    const url = environment.backend + 'product.add';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, newArticle, httpOptions).pipe(
      tap(_ => console.log(`added article`)),
      catchError(this.handleError<any>(`add article not working`))
    );
  }

  listProductNumbers(): Observable<any> {
    const url = environment.backend + 'product.number.list';
    const data = {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(_ => console.log(`fetched customer numbers`)),
      catchError(this.handleError<any>(`list customer numbers`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
