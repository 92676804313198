export class Offer {
    angebotnr: string;
    datum: string;
    freidatum: string;
    kundennr: string;
    kunde: string;
    adresse: string;
    plz: string;
    ort: string;
    land: string;
    tel1: string;
    fax1: string;
    ansprechpartnerintern: string;
    ansprechpartnerextern: string;
    nref: string;
    vref: string;
    position: number;
    artikelnr: string;
    bezeichnung1: string;
    bezeichnung3: string;
    menge: number;
    vpreis: number;
    epreis: number;
    summe: number;
    summee: number;
    rabatt: number;
    einheit: string;
    total: number;
    totale: number;
    summeende: number;
    miseafob: number;
    transport: number;
    zahlungsbedingungen: string;
    lieferbedingungen: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
    text6: string;
    mwst: number;
    vertreter: string;
    vertreter1: string;
    vertreter2: string;
    anzahltagen: number;
    projektmarge: number;
    text7: number;
    text8: number;
    text9: number;
    text10: number;
    text11: string;
    text12: string;
    text13: string;
    text14: string;
    id: number;
    positions: any[];
}
