import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OfferService {

  constructor(private http: HttpClient) {
  }

  getOffer(id): Observable<any> {
    const url = environment.backend + 'offer.get';
    const data = {
      id
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(_ => console.log(`fetched Offer`)),
      catchError(this.handleError<any>(`sorry, but the offer cannot be fetched`))
    );
  }

  listOfferNumbers(): Observable<any> {
    const url = environment.backend + 'offer.number.list';
    const data = {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(_ => console.log(`fetched inquiry numbers`)),
      catchError(this.handleError<any>(`list inquiry numbers`))
    );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
