<div class="container-fluid p-1">
  <div class="mx-auto mt-2" style="width: 95%;">
    <!-- Bar -->
  </div>
</div>

<div class="container-fluid p-1">
  <div class="mx-auto mt-2" style="width: 95%;">
    <div class="row">
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Vertreter_ID</span>
          </div>
          <input NgModel name="vertreterId" type="text" class="form-control form-control-sm">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Anfragsdatum</span>
          </div>
          <input NgModel name="inquiryDate" type="date" class="form-control form-control-sm">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Enddatum</span>
          </div>
          <input NgModel name="endDate" type="date" class="form-control form-control-sm">
        </div>
      </div>
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Gesuchter Text</span>
          </div>
          <input NgModel name="gesuchterText" type="text" class="form-control form-control-sm">
        </div>
      </div>
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Module auswählen</label>
          </div>
          <select class="custom-select" id="inputGroupSelect01">
            <option selected></option>
          </select>
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Provision 1 / Vertreter 1</label>
          </div>
          <select class="custom-select" id="inputGroupSelect01">
            <option selected></option>
          </select>
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Provision 2 / Vertreter 2</label>
          </div>
          <select class="custom-select" id="inputGroupSelect01">
            <option selected></option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Jahr</span>
          </div>
          <input NgModel name="jahr" type="text" class="form-control form-control-sm">
        </div>
        <button type="button" class="btn btn-primary btn-lg btn-block">Suchen</button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <input NgModel name="gesuchterText" type="text" class="form-control form-control-sm">
        </div>
      </div>
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Rech Situation</label>
          </div>
          <select class="custom-select" id="inputGroupSelect01">
            <option selected></option>
          </select>
          <button class="btn btn-dark btn-sm ml-2">Bericht</button>
          <button class="btn btn-danger btn-sm ml-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16">
              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
              <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
<div class="col">
  <hr>
  <table class="table table-striped table-dark">
    <thead>
    <tr>
      <th scope="col">Text14</th>
      <th scope="col">Auftrag_Nr</th>
      <th scope="col">Datum</th>
      <th scope="col">Summe-ende</th>
      <th scope="col">Gestamt Provision</th>
      <th scope="col">Vertreter Provision</th>
      <th scope="col">Vertreter</th>
      <th scope="col">Freidatum</th>
      <th scope="col">Kunden-Nr</th>
      <th scope="col">Kunde</th>
      <th scope="col">Adresse</th>
      <th scope="col">PLZ</th>
      <th scope="col">ORT</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th scope="row"></th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    </tbody>
  </table>
  <hr>
  <table class="table table-striped table-info">
    <thead>
    <tr>
      <th scope="col">Summe von Rechnungsbeträge</th>
      <th scope="col">Summe von Gesamtprovisionen</th>
      <th scope="col">Summe von Vertreterprovision</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th scope="row"></th>
      <td></td>
      <td></td>
    </tr>
    </tbody>
  </table>
</div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Anfangsdatum Abschlag</span>
          </div>
          <input NgModel name="anfragsdatum" type="text" class="form-control form-control-sm">
        </div>
      </div>
      <div class="col-3">

      </div>
      <div class="col-3">

      </div>
      <div class="col-3">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Umsatz</span>
          </div>
          <input NgModel name="umsatz" type="text" class="form-control form-control-sm">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <table class="table table-striped table-info">
          <thead>
          <tr>
            <th scope="col">Summe von Rechnungsbeträge</th>
            <th scope="col">Summe von Gesamtprovisionen</th>
            <th scope="col">Summe von Vertreterprovision</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row"></th>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-2">
        <button class="btn btn-dark">Abschläge</button>
        <button class="btn btn-dark ml-2">Abschläge</button>
      </div>
      <div class="col-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Summe Provision</span>
            </div>
            <input NgModel name="summeProvision" type="text" class="form-control form-control-sm">
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Restprovision Total</span>
            </div>
            <input NgModel name="resteProvision" type="text" class="form-control form-control-sm">
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Summe Abschläge</span>
            </div>
            <input NgModel name="summeAbs" type="text" class="form-control form-control-sm">
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Summe Prämien</span>
            </div>
            <input NgModel name="summePra" type="text" class="form-control form-control-sm">
          </div>
        </div>
      </div>
  </div>
</div>
