import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'auftrag-offer',
  templateUrl: './auftrag.component.html',
  styleUrls: ['./auftrag.component.css']
})

export class AuftragComponent {

}
