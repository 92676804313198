<div class="container-fluid p-1">
  <div class="mx-auto mt-2" style="width: 95%;">
    <!-- Bar -->
  </div>
</div>

<div class="container-fluid p-1">
  <div class="mx-auto mt-2" style="width: 95%;">
    <div class="row">
      <div class="col-4">
        <!-- Empty for design purpose -->
      </div>
      <div class="col-4 alert alert-primary" role="alert">
        <div align="center">
        <h2>Kundenbank</h2>
        </div>
        <hr>
        <div>
          <h6>Client : </h6>
        </div>
        <hr>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Kunden Nr</span>
          </div>
          <input ngModel name="kundeNumber" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Kunde</span>
          </div>
          <textarea name="kundeName" type="text" class="form-control" placeholder="" rows="3"></textarea>
        </div>
        <hr>
        <h6>Bank : </h6>
        <hr>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bankname</span>
          </div>
          <textarea ngModel name="bankName" type="text" class="form-control" placeholder="" rows="3"></textarea>
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">BankNr</span>
          </div>
          <input ngModel name="bankNr" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bankansprechpartner</span>
          </div>
          <input ngModel name="bankAnsprechpartner" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank Adresse</span>
          </div>
          <input ngModel name="bankAdresse" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank PLZ</span>
          </div>
          <input ngModel name="bankPlz" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank ORT</span>
          </div>
          <input ngModel name="bankOrt" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank Land</span>
          </div>
          <input ngModel name="bankLand" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank Tel</span>
          </div>
          <input ngModel name="bankTel" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank Fax</span>
          </div>
          <input ngModel name="bankFax" type="text" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank Text 1</span>
          </div>
          <textarea ngModel name="bankText1" type="text" class="form-control" placeholder="" rows="3"></textarea>
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Bank Text 2</span>
          </div>
          <textarea ngModel name="bankText2" type="text" class="form-control" placeholder="" rows="3"></textarea>
        </div>
        <hr>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Erstellungsdatum</span>
          </div>
          <input ngModel name="erstellungsdatum" type="date" class="form-control" placeholder="">
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Änderungsdatum</span>
          </div>
          <input ngModel name="anderungsdatum" type="date" class="form-control" placeholder="">
        </div>
        <button class="btn btn-primary" type="button">Create Client Bank</button>
      </div>
      <div class="col-4">
        <!-- Empty for design purpose -->
      </div>
    </div>
  </div>
</div>
