import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ProductsComponent} from './products/products.component';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule} from '@angular/forms';
import {InquiriesComponent} from './inquiries/inquiries.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProjectsComponent } from './projects/projects.component';
import { OfferComponent } from './offer/offer.component';
import { AuftragComponent } from './auftrag/auftrag.component';
import { RechnungComponent } from './rechnung/rechnung.component';
import { BestellungComponent } from './bestellung/bestellung.component';
import { VersandauftragComponent } from './versandauftrag/versandauftrag.component';
import { DeliveryadresseComponent } from './deliveryadresse/deliveryadresse.component';
import { ClientbankComponent } from './kundenbank/clientbank.component';
import { ProvisionComponent } from './provision/provision.component';
import {DropdownComponent} from './dropdownitem/dropdown.component';
import { LieferscheinComponent} from './lieferschein/lieferschein.component';

const appRoutes: Routes = [
  {path: 'product/:id', component: ProductsComponent},
  {path: 'product/search/:search_string', component: ProductsComponent},
  {path: 'inquiry/:id', component: InquiriesComponent},
  {path: 'inquiry', component: InquiriesComponent},
  {path: 'project', component: ProjectsComponent},
  {path: 'project/:id', component: ProjectsComponent},
  {path: 'offer', component: OfferComponent},
  {path: 'auftrag', component: AuftragComponent},
  {path: 'rechnung', component: RechnungComponent},
  {path: 'bestellung', component: BestellungComponent},
  {path: 'shipping', component: VersandauftragComponent},
  {path: 'deliveryadresse', component: DeliveryadresseComponent},
  {path: 'clientbank', component: ClientbankComponent},
  {path: 'provision', component: ProvisionComponent},
  {path: 'dropdown', component: DropdownComponent},
  {path: 'lieferchein', component: LieferscheinComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    InquiriesComponent,
    ProductSearchComponent,
    ProjectsComponent,
    OfferComponent,
    AuftragComponent,
    RechnungComponent,
    BestellungComponent,
    VersandauftragComponent,
    DeliveryadresseComponent,
    ClientbankComponent,
    ProvisionComponent,
    DropdownComponent,
    LieferscheinComponent,
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {useHash: true} // using hash to avoid conflicts with Bitrix routing
    ),
    HttpClientModule,
    BrowserModule,
    RouterModule,
    NgxDatatableModule,
    FormsModule,
    BrowserAnimationsModule,
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
