import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {ProductsService} from './products.service';
import {ActivatedRoute} from '@angular/router';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  columns = [{prop: 'ArtikelNr'}, {prop: 'EPreis'}, {prop: 'Bezeichnung2'}, {prop: 'Bemerkung'}];
  public searchString = '';
  protected message = '';
  products = [];
  temp = [];
  loadingIndicator = false;
  reorderable = true;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private productsService: ProductsService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // this.searchString = params.search_string;
      // this.loadProducts();
    });
  }

  loadProducts(): void {
    this.loadingIndicator = true;
    this.products = [];
    this.productsService.searchProductsByString(this.searchString).subscribe(
      (result) => {
        this.loadingIndicator = false;
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.products = result.data;
          this.temp = result.data;
          this.message = result.message;
          console.log(this.products);
        } else {
          this.products = [];
          console.log('No search results');
        }
      }
    );
  }

  updateFilter(event): void {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter((d) => {
      return d.Bezeichnung2.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.products = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
