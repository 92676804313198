import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {GadgetsService} from '../gadgets/gadgets.service';
import {Offer} from './offer.model';
import {OfferService} from './offer.service';


@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})

export class OfferComponent implements OnInit {

  public id = null;
  gadgets: any[];
  transport: any[];
  fob: any[];
  payments: any[];
  delivery: any[];
  destination: any[];
  offerNumbers: Array<any> = [];
  offer = new Offer();

  loadingIndicator = false;
  reorderable = true;
  selectedProducts = [];
  SelectionType = SelectionType;
  tempProducts: any[];
  private message= '';
  lastsum: number;

  editing = {};
  rows = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild(DatatableComponent) tableProducts: DatatableComponent;

  constructor(private route: ActivatedRoute,private offerService: OfferService, private modalService: BsModalService, private http: HttpClient, private gadgetsService: GadgetsService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.loadGadgets();
      this.loadOffersNumbers();
      this.getOffer(this.id);
    });
  }

  loadGadgets(): void {
    this.gadgetsService.listGadgets().subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.gadgets = result.data;
          this.transport = result.transport;
          this.fob = result.fob;
          this.payments = result.payments;
          this.delivery = result.delivery;
          this.destination = result.destination;
          console.log(this.gadgets);
          console.log(this.transport);
          console.log(this.fob);
          console.log(this.payments);
          console.log(this.delivery);
          console.log(this.destination);
        } else {
          console.log('No projects');
        }
      }
    );
  }

  getOffer(id): void {
    this.loadingIndicator = true;
    this.offer = new Offer();
    this.offerService.getOffer(id).subscribe(
      (result) => {
        this.loadingIndicator = false;
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.offer = result.data;
          this.offer.positions = result.positions;
          this.rows = result.positions;
          this.tempProducts = result.positions;
          this.message = result.message;
          console.log(this.offer);
        } else {
          this.offer = null;
          console.log('No Offer available with this ID');
        }
      }
    );
  }

  loadOffersNumbers(): void {
    this.offerService.listOfferNumbers().subscribe(
      (result) => {
        if (result !== undefined && result.hasOwnProperty('data')) {
          this.offerNumbers = result.data;
          this.message = result.message;
          console.log(this.offerNumbers);
        } else {
          this.offer = null;
          console.log('No offers');
        }
      }
    );
  }

  onActivate(event): void {
    console.log('Activate Event', event);
  }

  onSelect({selected}): void {
    console.log('Select Event', selected, this.selectedProducts);

    this.selectedProducts.splice(0, this.selectedProducts.length);
    this.selectedProducts.push(...selected);
  }

  updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    console.log('UPDATED!', this.rows[rowIndex])
    const url = environment.backend + 'inquiry.update';
    this.http.post(url,
      this.rows[rowIndex]
    ).subscribe(responseDate => {
      console.log(responseDate);
    });
    // this.getInquiry(this.inquiry.AnfrageNR);
  }

  lastSumCompute(a, b, c, d){
    return this.lastsum = a + b + c + d;
  }

}
