import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'dropdown-offer',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})

export class DropdownComponent {

}
