<div class="modal-header">
  <h4 class="modal-title pull-left">Packstück einlagern</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="row g-3">
    <div class="col-auto">
      <input type="text" class="form-control" name="searchText" [(ngModel)]="searchString" placeholder="In Bezeichnung2 suchen">
    </div>
    <div class="col-auto">
      <button (click)="loadProducts()" class="btn btn-primary mb-3">Suchen</button>
    </div>
    <input
      type='text'
      class="form-control"
      placeholder='Filter'
      (keyup)='updateFilter($event)'
    />
  </form>
  <ngx-datatable #table
                 class="ngx-datatable bootstrap fixed-header virtualized"
                 [rows]="products"
                 [columns]="columns"
                 [loadingIndicator]="loadingIndicator"
                 [columnMode]="'force'"
                 [rowHeight]="'auto'"
                 [reorderable]="reorderable">
    > </ngx-datatable>
</div>
