import {Article} from '../products/article.model';

export class ProjectArticle extends Article {
  ProjektNR: string;
  artikelNrLief: string;
  suffix: string;
  zahler: string;
  status: string;
  exportData: string;
  quantity: number;
  unit: string;
  sellPriceTwo: number;
  summe: number;
  projectMarge: number;
  summeE: number;
  sellPriceProject: number;

  setArticleValues(article: Article): void {
    this.ArtikelNr = article.ArtikelNr;
    this.Bezeichnung1 = article.Bezeichnung1;
    this.Bezeichnung2 = article.Bezeichnung2;
    this.Bezeichnung3 = article.Bezeichnung3;
    this.Bezeichnung4 = article.Bezeichnung4;
    this.Bezeichnung5 = article.Bezeichnung5;
    this.Bezeichnung6 = article.Bezeichnung6;
    this.Lieferant = article.Lieferant;
    this.Kunde = article.Kunde;
    this.Industriebereich = article.Industriebereich;
    this.Einheit = article.Einheit;
    this.VPreis = article.VPreis;
    this.Bemerkung = article.Bemerkung;
    this.Erstelldatum = article.Erstelldatum;
    this.Änderungsdatum = article.Änderungsdatum;
    this.EPreis = article.EPreis;
    this.Datenblatt = article.Datenblatt;
    this.Kontrolle = article.Kontrolle;
  }

  setProjektNR(ProjektNR: string): void {
    this.ProjektNR = ProjektNR;
  }
}
