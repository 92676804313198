import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Project} from './project.model';
import {ProjectArticle} from './project-article.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private http: HttpClient) {
  }

  getProject(projectNumber): Observable<any> {
    const url = environment.backend + 'project.get';
    const data = {
      ProjektNR: projectNumber
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(_ => console.log(`fetched project`)),
      catchError(this.handleError<any>(`get project`))
    );
  }

  addProject(newProject: Project): Observable<any> {
    const url = environment.backend + 'project.add';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, newProject, httpOptions).pipe(
      tap(_ => console.log(`added project `)),
      catchError(this.handleError<any>(`add project not working`))
    );
  }

  listProjectsNumbers(): Observable<any> {
    const url = environment.backend + 'project.number.list';
    const data = {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(_ => console.log(`fetched project numbers`)),
      catchError(this.handleError<any>(`list project numbers`))
    );
  }

  addProjectArticle(newProjectArticle: ProjectArticle): Observable<any> {
    const url = environment.backend + 'project.article.add';
    // these properties don't exist in DB
    delete newProjectArticle.Bezeichnung5;
    delete newProjectArticle.Bezeichnung6;
    delete newProjectArticle.Lieferant;
    delete newProjectArticle.Kunde;
    delete newProjectArticle.Industriebereich;
    delete newProjectArticle.Bemerkung;
    delete newProjectArticle.Änderungsdatum;
    delete newProjectArticle.Datenblatt;
    delete newProjectArticle.Kontrolle;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, newProjectArticle, httpOptions).pipe(
      tap(_ => console.log(`added project article`)),
      catchError(this.handleError<any>(`add project article`))
    );
  }

  removeProjectArticle(projectArticle: ProjectArticle): Observable<any> {
    const url = environment.backend + 'project.article.remove';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + this.apiToken
      })
    };
    return this.http.post<any>(url, projectArticle, httpOptions).pipe(
      tap(_ => console.log(`deleted project article`)),
      catchError(this.handleError<any>(` project article delete not working`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}



