import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'lieferschein-offer',
  templateUrl: './lieferschein.component.html',
  styleUrls: ['./lieferschein.component.css']
})

export class LieferscheinComponent {

}
