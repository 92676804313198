import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {NgForm} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'rechnung-app',
  templateUrl: './rechnung.component.html',
  styleUrls: ['./rechnung.component.css']
})

export class RechnungComponent {

}
