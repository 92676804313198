export class Project {
  id: string;
  ProjektNR: string;
  KundenNr: string;
  Kunde: string;
  Adresse: string;
  Plz: string;
  Ort: string;
  Land: string;
  Tel1: string;
  Fax1: string;
  Email1: string;
  Vref: string;
  Nref: string;
  Freidatum: string;
  AnsprechpartnerIntern: string;
  Ansprechpartnerextern: string;
  Projektname: string;
  To_do: string;
  BelegDatum: string;
  positions: any[];
  inquiries: any[];
  angebot: any[];
  land: string;
}

export class Products {
  ArtikelNr:string;
  Bezeichnung1:string;
  Bezeichnung2:string;
  Bezeichnung3:string;
  Bezeichnung4:string;
  Bezeichnung5:string;
  Bezeichnung6:string;
  Lieferant:string;
  Kunde:string;
  Industriebereich:string;
  Einheit:string;
  VPreis:string;
  Bemerkung:string;
  Erstelldatum:string;
  Änderungsdatum:string;
  EPreis:string;
  Datenblatt:string;
  Kontrolle:string;
  ProjektNr:string;

}

export class Angebot {
  angebotnr:string;
  datum:string;
  freidatum:string;
  kundennr:string;
  kunde:string;
  adresse:string;
  plz:string;
  ort:string;
  land:string;
  tel1:string;
  fax1:string;
  ansprechpartnerintern:string;
  ansprechpartnerextern:string;
  nref:string;
  vref:string;
  position:string;
  artikelnr:string;
  bezeichnung1:string;
  bezeichnung3:string;
  menge:string;
  vpreis:string;
  epreis:string;
  summe:string;
  summee:string;
  rabatt:string;
  einheit:string;
  total:string;
  totale:string;
  summeende:string;
  miseafob:string;
  transport:string;
  zahlungsbedingungen:string;
  lieferbedingungen:string;
  text1:string;
  text2:string;
  text3:string;
  text4:string;
  text5:string;
  text6:string;
  mwst:string;
  vertreter:string;
  vertreter1:string;
  vertreter2:string;
  anzahltagen:string;
  projektmarge:string;
  text7:string;
  text8:string;
  text9:string;
  text10:string;
  text11:string;
  text12:string;
  text13:string;
  text14:string;
  id:string;

}

export class Auftrag {

}

export class Rechnung {

}

export class Bestellung {

}

export class lieferschien {

}

export class versandauftrag {

}

export class kalkulation {

}
