<div>
  <!-- begin navbar -->
  <nav class="navbar mb-3 sticky-top navbar-expand-xl navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="#">ESS-Mondial</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" routerLink="#"><b>Project</b> <span class="sr-only">(current)</span></a>
        </li>
        <!-- -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" routerLink="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Stammdaten
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <li class="dropdown-header">Articles</li>
          <a class="dropdown-item" routerLink="/article/new">Nouveau</a>
          <a class="dropdown-item" routerLink="/article/update">Modifier</a>
          <a class="dropdown-item" routerLink="/article/list">Liste</a>
        <div class="dropdown-divider"></div>
        <li class="dropdown-header">Client</li>
          <a class="dropdown-item" routerLink="/client/new">Nouveau</a>
          <a class="dropdown-item" routerLink="/client/update">Modifier</a>
          <a class="dropdown-item" routerLink="/client/list">Liste</a>
        <div class="dropdown-divider"></div>
        <li class="dropdown-header">Fournisseur</li>
          <a class="dropdown-item" routerLink="/supplier/new">Nouveau</a>
          <a class="dropdown-item" routerLink="/supplier/update">Modifier</a>
          <a class="dropdown-item" routerLink="/supplier/list">Liste</a>
    </div>
    <!-- -->
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" routerLink="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Verkauf
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    <li class="dropdown-header">Angebotsbearbeitung</li>
    <a class="dropdown-item" routerLink="/angebot">Angebot</a>
    <a class="dropdown-item" routerLink="/angebot/list">Tabelle</a>
    <div class="dropdown-divider"></div>
    <li class="dropdown-header">Auftragsbearbeitung</li>
    <a class="dropdown-item" routerLink="/auftrag">Auftrag</a>
    <a class="dropdown-item" routerLink="/auftrag/list">Tabelle</a>
    <div class="dropdown-divider"></div>
    <li class="dropdown-header">Rechnungsbearbeitung</li>
    <a class="dropdown-item" routerLink="/rechning">Rechnung</a>
    <a class="dropdown-item" routerLink="/rechnung/list">Tabelle</a>
</div>
</li>
<!-- -->
<li class="nav-item dropdown">
  <a class="nav-link dropdown-toggle" routerLink="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Einkauf
  </a>
  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
<li class="dropdown-header">Anfragebearbeitung</li>
<a class="dropdown-item" routerLink="/angebot">Angebot</a>
<a class="dropdown-item" routerLink="/angebot/list">Tabelle</a>
<div class="dropdown-divider"></div>
<li class="dropdown-header">Bestellungsbearbeitung</li>
<a class="dropdown-item" routerLink="/bestellung">Bestellung</a>
<a class="dropdown-item" routerLink="/bestellung/list">Tabelle</a>
</div>
<!-- -->
<li class="nav-item dropdown">
  <a class="nav-link dropdown-toggle" routerLink="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Verkauf
  </a>
  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
<li class="dropdown-header"> Lieferscheinbearbeitung</li>
<a class="dropdown-item" routerLink="/lieferschein">Lieferschein</a>
<a class="dropdown-item" routerLink="/lieferschein/list">Tabelle</a>
<div class="dropdown-divider"></div>
<li class="dropdown-header">Versandsbwicklung</li>
<a class="dropdown-item" routerLink="/versandauftrag">Versandauftrag</a>
<a class="dropdown-item" routerLink="/versandauftrag/list">Tabelle</a>
<div class="dropdown-divider"></div>
<li class="dropdown-header">Adressen und Etiketten</li>
<a class="dropdown-item" routerLink="/versandadresse">Versandadresse</a>
<a class="dropdown-item" routerLink="/versandadresse/list">Tabelle</a>
<div class="dropdown-divider"></div>
<li class="dropdown-header">Kunden bank</li>
<a class="dropdown-item" routerLink="/kundenbank">Kundenbank nummer</a>
</div>
</li>
<!-- -->
<li class="nav-item dropdown">
  <a class="nav-link dropdown-toggle" routerLink="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Provisionverwaltung
  </a>
  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

    <a class="dropdown-item" routerLink="#">Abfrage Provisionen</a>
    <a class="dropdown-item" routerLink="#">Verwaltung Abschläge</a>
  </div>
</li>
<!-- -->
<li class="nav-item dropdown">
  <a class="nav-link dropdown-toggle btn btn-secondary" routerLink="#"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Dashboard <span class="badge badge-warning">Pro</span>
  </a>
  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    <a class="dropdown-item" routerLink="#">Analytics & Insights</a>
    <a class="dropdown-item" routerLink="#">Prediction</a>
  </div>
</li>
<!-- -->
</ul>
</div>
<div class="dropdown">
  <a class="btn btn-success dropdown-toggle" routerLink="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Edit
  </a>

  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
    <a class="dropdown-item" routerLink="#">Create</a>
    <a class="dropdown-item" routerLink="#">Update</a>
    <a class="dropdown-item" routerLink="#">Delete</a>
  </div>
</div>
</nav>
<!-- End navbar-->
  <router-outlet></router-outlet>
</div>
